import React, { useState, useCallback } from 'react';
import _debounce from 'lodash/debounce';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {size} from "lodash";


interface InputProps {
  id?: string | number;
  type?: string;
  label?: string;
  autoFocus?: boolean;
  placeholder?: string;
  height?: InputHeight;
  theme?: InputTheme;
  disabled?: boolean;
  classNames?: string;
  isTouched?: boolean;
  isSearch?: boolean;
  debounceTime?: number;
  validationRules?: any;
  value?: string | number | null;
  error?: any;
  onChange: (e: any) => void;
}


type InputHeight = 'regular' | 'medium' | 'small' | 'large';
type InputTheme = 'form' | 'search';


const Input = ({
                 label,
                 id,
                 type = 'text',
                 value,
                 autoFocus,
                 placeholder,
                 classNames,
                 height,
                 isTouched,
                 theme,
                 debounceTime = 0,
                 isSearch = false,
                 validationRules,
                 disabled,
                 error,
                 onChange,
               }: InputProps) => {


  const [localValue, setLocalValue] = useState('');
  const [isShowPass, setIsShowPass] = useState(false);
  const [inputType, setInputType] = useState(type);
  const calculateInputHeight = (height: string = 'regular') => {
    switch (height) {
      case 'small':
        return 'h-8';
      case 'regular':
        return 'h-10'
      case 'medium':
        return 'h-12';
      case 'large':
        return 'h-14';
      default:
        return 'h-10';
    }
  }


  const onChangeHandler = (e: any) => {
    onChange(e.target.value);
    setLocalValue(e.target.value);
  }


  const debounceFn = useCallback(_debounce(onChangeHandler, debounceTime), []);


  const showPasswordHandler = (value: boolean): void => {
    setIsShowPass(value);
    if (value) setInputType('text');
    else setInputType('password');
  }


  const isTouchedAndValid = isTouched && localValue && !error;


  return (
    <div className="relative">
      {label &&
          <label className="block text-gray-700 text-sm font-bold mb-1">
            { label }
          </label>
      }
      <div className="relative">
        <input
          id={id}
          type={inputType}
          autoFocus={autoFocus}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          {...validationRules}
          onChange={debounceFn}
          className={`${isSearch && 'pl-10'} ${classNames} ${calculateInputHeight(height)} ${error && !disabled && 'border-2 border-red-600'} ${isTouchedAndValid && !disabled && 'border-2 border-green-600'} ${!error && 'focus:ring-2 ring-base-500'} ${type === 'password' && 'pr-16'} caret-primary-900 border ${!disabled && 'hover:border-base-500'} w-full px-3 rounded-lg text-gray-700 outline-none leading-tight animation duration-300 ease-in-out`}
        />
        {isSearch &&
          <div className={`absolute left-3 top-0 ${calculateInputHeight(height)} flex items-center cursor-pointer text-gray-600`}>
            <FontAwesomeIcon icon={faSearch} className="text-gray-300"/>
          </div>
        }
        {error && error.message && !disabled &&
            <div className={`absolute ${type === 'password' ? 'right-9' : 'right-2'} top-3.5 cursor-pointer`}>
            {/*<Icon name="inputWarning" />*/}
            </div>
        }
        {isTouchedAndValid && !disabled &&
            <div className={`absolute ${type === 'password' ? 'right-9' : 'right-2'} top-3.5 cursor-pointer`}>
              {/*<Icon name="inputValid" />*/}
            </div>
        }
        {type === 'password' && !isShowPass &&
            <div onClick={() => showPasswordHandler(true)} className="absolute right-2 top-4 cursor-pointer">
              {/*<Icon name="eyeShow" />*/}
            </div>
        }
        {type === 'password' && isShowPass &&
            <div onClick={() => showPasswordHandler(false)} className="absolute right-2 top-3.5 cursor-pointer">
              {/*<Icon name="eyeHide" />*/}
            </div>
        }
      </div>
      {/* TODO: Add icon for THEME type search */}
      {error && error.type && error.message && (
        <small className="absolute left-0 -bottom-5 text-red-600">*{error.message}</small>
      )}
    </div>
  );
};


export default Input;
