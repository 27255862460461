import http from 'libs/http';

class BenefitApi {
  getCategories = () => {
    return http.get('/v2/admin/categories');
  };

  configureCompany = (companyId: number, payload: any) => {
    return http.patch(`/v2/admin/companies/${companyId}/`, payload);
  };

  getFixedBenefits = (url: string) => {
    return http.get(url);
  };

  addFixedBenefit = (payload: any) => {
    return http.post('/v2/admin/benefits/', payload);
  };

  editFixedBenefit = (benefitId: number, payload: any) => {
    return http.patch(`/v2/admin/benefits/${benefitId}/`, payload);
  };

  uploadImages = (payload: any) => {
    return http.post('v2/admin/benefit-picture/', payload);
  };

  deleteImage = (imageId: number) => {
    return http.delete(`v2/admin/benefit-picture/${imageId}`);
  };

  deleteFixedBenefit = (benefitId: any) => {
    return http.patch(`/v2/admin/benefits/${benefitId}/`, { hide: true });
  };

  getProviders = (url: string) => {
    return http.get(url);
  };

  getCompany = (companyId: number) => {
    return http.get(`/v2/admin/companies/${companyId}`);
  };

  configureRestrictions = (companyId: number, payload: any) => {
    return http.patch(`/v2/admin/table-file-create-users/`, payload);
  };
}


export default BenefitApi;