import React from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

interface PieChartProps {
  id: any;
  data: any;
  size: number;
}

const chartOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  radius: 105,
  plugins: {

    // tooltip: {
    //   enabled: true, // Omogućava tooltip na hover
    //   callbacks: {
    //     label: function(tooltipItem: any) {
    //       const dataset = tooltipItem.dataset;
    //       const index = tooltipItem.dataIndex;
    //       const value = dataset.data[index];
    //       return `${dataset.label || ''}: ${value}%`;
    //     },
    //   },
    //   backgroundColor: '#333', // Boja pozadine za tooltip
    //   titleFont: { size: 14 },
    //   bodyFont: { size: 12 },
    //   padding: 10,
    //   cornerRadius: 4,
    // },
    legend: {
      display: false,
    },
    datalabels: {
      color: '#ffffff',
      font: {
        weight: 'bold',
        size: 11,
      },
      formatter: (value: any) => `${String(value).substring(0, 2)}%`,
    },
  },
};

const PieChart = ({ id, data, size }: PieChartProps) => {

  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <Doughnut
        id={id}
        data={data}
        width={size}
        height={size}
        className="relative"
        options={chartOptions}
      />
    </div>
  );
};

export default PieChart;
