import React, {useEffect, useRef, useState} from 'react';
import Input from 'components/Input';
import { toast } from 'react-toastify';
import {
  benefitActions,
  getProviders,
  getCategories,
  getCompany, configureCompany,
} from 'modules/benefit/services/benefit.store';
import { useDispatch, useSelector } from 'react-redux';
import {faCheckCircle, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroll-component";

const Restrictions = () => {

  const dispatch: any = useDispatch();
  const providers: any = useSelector((state: any) => state.benefitStore.providers);
  const user: any = useSelector((state: any) => state.authStore.user);
  const categories: any = useSelector((state: any) => state.benefitStore.categories);
  const searchString: any = useSelector((state: any) => state.benefitStore.searchString);
  const nextPage: any = useSelector((state: any) => state.benefitStore.nextPage);
  const count: any = useSelector((state: any) => state.benefitStore.count);
  const company: any = useSelector((state: any) => state.benefitStore.company);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [categorySearchString, setCategorySearchString] = useState<any>('');
  const [isShowRestrictedCategories, setIsShowRestrictedCategories] = useState<any>(false);
  const [isShowRestrictedProviders, setIsShowRestrictedProviders] = useState<any>(false);
  const [tableHeight, setTableHeight] = useState<number>(0);

  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === providers.length;
  }, [count, providers]);

  useEffect(() => {
    return () => {
      dispatch(benefitActions.setNextPage(null));
      dispatch(benefitActions.setCount(0));
      dispatch(benefitActions.resetProviders());
    }
  }, []);

  const fetchMoreProviders = async (resetData: boolean = false): Promise<void> => {
    try {
      await dispatch(getProviders(resetData));
    } catch (e: any) {
      toast.error(e.data.detail);
    }
  };


  const searchProviderHandler = (payload: any): void => {
    dispatch(benefitActions.setSearchString(payload));
  }

  const searchCategoryHandler = (payload: string): void => {
    let filteredCopy = [...categories];
    const string: string = payload.toLowerCase();
    filteredCopy = filteredCopy.filter((c: any) => c.name.toLowerCase().includes(string));
    setCategorySearchString(payload);
    setFilteredCategories([...filteredCopy]);
  };

  const configureCategoriesHandler = async (categoryId: number): Promise<any> => {
    let restrictionsCategories: any = [...company.restrictionsCategories];
    if (company.restrictionsCategories.includes(categoryId)) {
      restrictionsCategories = company.restrictionsCategories.filter((e: any)=> e !== categoryId);
    } else {
      restrictionsCategories.push(categoryId);
    }

    try {
      await dispatch(configureCompany({restrictionsCategories}));
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const configureProvidersHandler = async (providerId: number): Promise<any> => {
    let restrictionsProviders: any = [...company.restrictionsProviders];
    if (company.restrictionsProviders.includes(providerId)) {
      restrictionsProviders = company.restrictionsProviders.filter((id: any)=> id != providerId);
    } else {
      restrictionsProviders.push(providerId);
    }

    try {
      await dispatch(configureCompany({restrictionsProviders}));
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };


  useEffect((): void => {
    fetchMoreProviders(true);
  }, [searchString]);

  useEffect((): void => {
    if (categories) setFilteredCategories(categories);
  }, [categories]);

  useEffect((): void => {
    dispatch(getCompany());
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(benefitActions.setCount(0));
      dispatch(benefitActions.setNextPage(null));
      dispatch(benefitActions.resetFixedBenefits());
      dispatch(benefitActions.resetProviders());
      dispatch(benefitActions.resetCategories());
    }
  }, []);

  useEffect(() => {
    searchCategoryHandler(categorySearchString);
  }, [categorySearchString]);

  const parseCategoryName = (name: string) => {
    if (name.split('/')[1]) return name.split('/')[1];
    else return name;
  }

  const getTableHeight = () => {
    let height: number =  document.documentElement.clientHeight - 315;
    setTableHeight(height);
  }

  useEffect(() => {
    getTableHeight();
  }, [providers]);


  return (
    <div className="flex items-start gap-x-10">
      <div className="w-1/2">
        <div className="w-full">
          <Input
            key="1"
            isSearch
            type='text'
            height='regular'
            debounceTime={400}
            onChange={searchProviderHandler}
            placeholder="Unesi naziv provajdera"
          />
        </div>
        <p className="flex-none font-bold text-gray-700 mt-1">OGRANIČENI PROVAJDERI:</p>
        <div id="list" className={`relative flex items-center pr-7 animation duration-200 ease-in-out`}>
              <div
                  className={`${!isShowRestrictedProviders ? 'flex flex-nowrap truncate' : 'flex flex-wrap truncate py-1'}  gap-1`}>
                {company && company.restrictionsProvidersObjects.length > 0 && company.restrictionsProvidersObjects.map((p: any) =>
                    <span key={p.id}
                          className="flex items-center justify-center h-5 px-1.5 py-0.5 rounded-full text-xs whitespace-nowrap font-medium bg-red-100 text-red-800 mr-1">
                {p.name}
              </span>
                )}
              </div>
              <div className="absolute top-0 right-1 h-full flex items-start">
                  <FontAwesomeIcon
                      icon={faChevronUp}
                      className={`${!isShowRestrictedProviders && 'transform -rotate-180'} animation duration-200 ease-in-out cursor-pointer`}
                      onClick={() => setIsShowRestrictedProviders(!isShowRestrictedProviders)}
                  />
              </div>
        </div>
        {(company && company.restrictionsProvidersObjects.length === 0) &&
            <span
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
            Nema ograničenih provajdera
          </span>
        }
        <ul className="max-h-[calc(100vh-310px)] mt-1 border rounded-lg overflow-hidden overflow-y-auto">
          {tableHeight > 0 && <InfiniteScroll
              dataLength={providers.length}
              next={fetchMoreProviders}
              height={tableHeight}
              hasMore={count !== providers.length}
              loader={""}
              scrollThreshold={1}
          >
            {providers && providers.length > 0 && providers.map((p: any) => p.country === user.country &&
                <li onClick={() => configureProvidersHandler(p.id)} key={p.id}
                    className="flex items-center justify-between bg-gray-50 px-5 py-2 border-b last:border-b-0 text-gray-600 hover:bg-base-600 hover:text-white cursor-pointer">
                    <p>{p.name ? p.name : ''}</p>
                  {company && company.restrictionsProviders.includes(p.id) &&
                      <FontAwesomeIcon icon={faCheckCircle} className="text-green-600"/>}
                </li>
            )}
            {providers && providers.length === 0 &&
                <li className="px-5 py-2 text-gray-600 cursor-pointer">
                    Nema pronađenih provajdera!
                </li>
            }
          </InfiniteScroll>
          }
        </ul>
      </div>
      <div className="w-1/2 select-none">
        <div className="w-full">
          <Input
            key="2"
            isSearch
            type='text'
            height='regular'
            // debounceTime={200}
            onChange={searchCategoryHandler}
            placeholder="Unesi naziv kategorije"
          />
        </div>
        <p className="flex-none font-bold text-gray-700 mt-1">OGRANIČENE KATEGORIJE:</p>
        <div className={`relative flex items-center pr-7 animation duration-200 ease-in-out`}>
          <div className={`${!isShowRestrictedCategories ? 'flex flex-nowrap truncate' : 'flex flex-wrap truncate py-1'}  gap-1`}>
            {company && company.restrictionsCategoriesObjects.length > 0 && company.restrictionsCategoriesObjects.map((c: any) =>
              c.id !== 1 &&
                <span key={c.id}
                      className="flex items-center justify-center h-5 px-1.5 py-0.5 rounded-full text-xs whitespace-nowrap font-medium bg-red-100 text-red-800 mr-1">
                  { parseCategoryName(c.name) }
                  {/*{ c.name }*/}
                </span>
            )}
          </div>
          <div className="absolute top-0 right-1 h-full flex items-start">
            <FontAwesomeIcon
              icon={faChevronUp}
              className={`${!isShowRestrictedCategories && 'transform -rotate-180'} animation duration-200 ease-in-out cursor-pointer`}
              onClick={() => setIsShowRestrictedCategories(!isShowRestrictedCategories)}
            />
          </div>
        </div>
        {company && company.restrictionsCategories.length === 0 &&
            <span
                className="inline-flex items-center px-2.5 mt-1 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
              Nema ograničenih kategorija
            </span>
        }
        <ul className="max-h-[calc(100vh-314px)] border rounded-lg overflow-hidden overflow-y-auto mt-1">
          {filteredCategories.length > 0 && filteredCategories.map((c: any) => c.id !== 1 &&
              <li onClick={() => configureCategoriesHandler(c.id)} key={c.id}
                  className="flex items-center justify-between bg-gray-50 px-5 py-2 border-b last:border-b-0 text-gray-600 hover:bg-base-600 hover:text-white cursor-pointer">
                  <p>{ parseCategoryName(c.name) }</p>
                {company && company.restrictionsCategories.includes(c.id) &&
                    <FontAwesomeIcon icon={faCheckCircle} className="text-green-600"/>}
              </li>
          )}
          {filteredCategories.length === 0 &&
              <li className="px-5 py-2 text-gray-600 cursor-pointer">
                  Nema pronađenih kategorija!
              </li>
          }
        </ul>
      </div>
    </div>
  );
};

export default Restrictions;