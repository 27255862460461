import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {faBullhorn, faDownload} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSuggestions } from 'modules/suggestions/services/suggestions.store';


const Suggestions = () => {

  const dispatch: any = useDispatch();
  const loading = useSelector((state: any) => state.loaderStore.loading);
  const suggestions: any = useSelector((state: any) => state.suggestionsStore.suggestions);


  useEffect(() => {
    dispatch(getSuggestions());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        dispatch(getSuggestions());
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return(
    <>
      {suggestions.length > 0 &&
          <section className="bg-white rounded-xl border overflow-hidden">
            {suggestions.length > 0 && suggestions.map((s: any) =>
              <div key={suggestions.id} className="flex items-center gap-x-5 py-8 border-b hover:bg-gray-100/50">
                <div className="w-96 pl-4 text-base-600 font-medium">
                  <p className="font-black text-black">{s.author.firstName} {s.author.lastName}</p>
                  <p>{s.author.email ? s.author.email : '/'}</p>
                </div>
                <div className="w-7/12 pl-5 border-l font-medium text-black/85" dangerouslySetInnerHTML={{ __html: s.text }}></div>
              </div>
            )}
          </section>
      }
      {suggestions.length === 0 &&
        <div className="h-[calc(100vh-190px)] text-gray-600 flex items-center justify-center border rounded-lg">
          <div className="text-center space-y-4">
            <FontAwesomeIcon icon={faBullhorn} className="text-4xl"/>
            <p className="font-medium">Nema pronađenih sugestija!</p>
          </div>
        </div>
      }
    </>
  );
};

export default Suggestions;