import React, {useEffect, useRef, useState} from 'react';
import http, {setHeaders} from "libs/http";
import { toast } from 'react-toastify';
import Input from 'components/Input';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import TextEditor from 'components/TextEditor';
import { ElementLoader } from 'components/Loaders';
import { modalActions } from 'store/modal/modal.store';
import { useDispatch, useSelector } from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import BenefitDocumentInput from "modules/benefit/UI/BenefitDocumentInput";
import {
  addFixedBenefit, deleteImage, editFixedBenefit,
  editPromo,
  getFixedBenefits,
  uploadImages
} from 'modules/benefit/services/benefit.store';

interface EditFixedBenefitsFormProps {
  benefit: any;
}

const EditFixedBenefitForm = ({ benefit }: EditFixedBenefitsFormProps) => {

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
      instructions: '',
      limitations: '',
    },
  });

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const [loading, setLoading] = useState(false);
  const [promoMaterial, setPromoMaterial] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const importRef = useRef<any>(null);
  const importImageRef = useRef<any>(null);
  const [description, setDescription] = useState<any>('');
  const [instructions, setInstructions] = useState<any>('');
  const [limitations, setLimitations] = useState<any>('');

  useEffect(() => {
    if (benefit.name) setValue('name', benefit.name);
    if (benefit.description) setDescription(benefit.description);
    if (benefit.instructions) setInstructions(benefit.instructions);
    if (benefit.limitations) setLimitations(benefit.limitations);
    if (benefit.promoMaterial.length > 0) setPromoMaterial(benefit.promoMaterial);
    if (benefit.pictures.length > 0) setImages(benefit.pictures);
  }, [benefit]);

  const onSubmit = async (data: any) => {
    let payload: any = {
      ...benefit,
      fixed: true,
      provider: user.company.id,
      name: data.name,
      limitations,
      description,
      instructions,
      promo_material: promoMaterial.map((e: any) => e.id).join(','),
      pictures: [...images],
    };

    const formData: FormData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    if (isValid) {
      try {
        await dispatch(editFixedBenefit(benefit.id, formData));
        dispatch(getFixedBenefits(true));
        dispatch(modalActions.closeModal());
        toast.success('Uspešno ste ažurirali fiksni benefit!');
      } catch (e: any) {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const openUploadPrompt = (): void => {
    importRef.current.click();
  }

  const openUploadImagePrompt = (): void => {
    importImageRef.current.click();
  }
  const uploadImageHandler = async (e: any): Promise<any> => {

    if ('id' in benefit) {
      let payload = new FormData()

      payload.append('position', '0');
      payload.append('benefit', benefit.id);
      payload.append('note', '');
      payload.append('image', e.target.files[0]);

      try {
        await setHeaders({ 'content-type': 'multipart/form-data' });
        const data = await dispatch(uploadImages(payload));
        await setHeaders({ 'content-type': 'application/json' });
        const imgs: any = [...images, data];
        setImages(imgs);
      } catch (e: any) {
        console.log(e, 'err');
      }
    }
  }

  const uploadPromoHandler = async (e: any): Promise<any> => {
    let req = new FormData()

    req.append('title', 'promo')
    req.append('file', e.target.files[0])

    try {
      const { data } = await http.post('v2/admin/promo/', req);
      let promo: any = [...promoMaterial, data];
      setPromoMaterial(promo);
      const payload = {
        promoMaterial: promo.map((e: any) => e.id).join(',')
      }
      await dispatch(editPromo(benefit.id, payload));
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  }

  const deletePromoHandler = async (promoId: number): Promise<any> => {
    try {
      await http.delete(`v2/admin/promo/${promoId}/`);
      const promo = promoMaterial.filter((e: any) => e.id != promoId);
      setPromoMaterial(promo);
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const deleteImageHandler = async (imageId: any): Promise<any> => {
    try {
      await dispatch(deleteImage(imageId));
      const imgs = images.filter((e: any) => e.id != imageId);
      setImages(imgs);
    } catch (e: any) {
      toast.error('Nešto nije u redu');
    }
  }

  const descriptionHandler = (e: any): void => {
    setDescription(e);
  }

  const limitationsHandler = (e: any): void => {
    setLimitations(e);
  }

  const instructionsHandler = (e: any): void => {
    setInstructions(e);
  }

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  }

  return (
    <div className="relative">
      <div className="p-7 space-y-7">
        <div>
          <Input
            type='text'
            label="Naziv"
            autoFocus
            height='medium'
            placeholder="Unesi naziv"
            isTouched={touchedFields.name}
            error={errors.name}
            validationRules={register('name', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div className="h-60">
          <TextEditor
            label="Opis"
            value={benefit.description}
            onChange={descriptionHandler}
          />
        </div>
        <div className="h-64 pt-2">
          <TextEditor
            label="Instrukcije"
            value={benefit.instructions}
            onChange={instructionsHandler}
          />
        </div>
        <div className="h-64">
          <TextEditor
            label="Ograničenja"
            value={benefit.limitations}
            onChange={limitationsHandler}
          />
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Prilog
            </label>
            <div className="flex flex-wrap items-center gap-6">
              {promoMaterial.map((p: any) =>
                <BenefitDocumentInput key={p.id} promo={p} onDelete={() => deletePromoHandler(p.id)}/>
              )}
            </div>
          </div>
          <div className="pt-3">
            <input onChange={uploadPromoHandler} hidden type="file" ref={importRef}/>
            <Button
              bg="outline-primary"
              className="w-10 flex items-center justify-center gap-x-2"
              size="regular"
              onClick={openUploadPrompt}
            >
              <FontAwesomeIcon icon={faPlus}/>
            </Button>
          </div>
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Slike
            </label>
            <div className="flex flex-wrap items-center gap-4">
              {images.map((img: any) =>
                <div key={img.image} className="flex items-end gap-x-2">
                  <img src={img.image} alt="" className="h-16 rounded-lg"/>
                  <div className="flex items-center">
                    <Button
                      bg="outline-warning"
                      className="w-10 mt-0.5 flex items-center justify-center gap-x-2"
                      size="regular"
                      onClick={() => deleteImageHandler(img.id)}
                    >
                      <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="pt-3">
            <input onChange={uploadImageHandler} hidden type="file" ref={importImageRef}/>
            <Button
              bg="outline-primary"
                className="w-10 flex items-center justify-center gap-x-2"
                size="regular"
                onClick={openUploadImagePrompt}
              >
                <FontAwesomeIcon icon={faPlus}/>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
        <Button
          bg="white"
          className="w-full flex items-center justify-center gap-x-2"
          size="regular"
          onClick={closeForm}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-full gap-x-2 relative"
          size="regular"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Ažuriraj
          {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
        </Button>
      </div>
    </div>
  );
};

export default EditFixedBenefitForm;