import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import AuthApi from './auth.api';
import { setHeaders } from 'libs/http';


const api: AuthApi = new AuthApi();


interface AuthState {
  user: any;
  token: string;
}
let userData;
let accessToken;
if (typeof window !== 'undefined') {
  userData = localStorage.getItem('user');
  accessToken = localStorage.getItem('user');
}


const initialState: AuthState = {
  user: userData ? JSON.parse(userData) : null,
  token: accessToken ? JSON.parse(accessToken) : null,
};


const authSlice: any = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: any, action: PayloadAction<AuthState, any>): any => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setToken: (state: any, action: PayloadAction<any, any>): any => {
      state.token = action.payload;
      setHeaders({ Authorization: `Token ${action.payload}` });
      localStorage.setItem('token', action.payload);
    },
    logOut: (state: any): any => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
});


export const login = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.login(payload);
      dispatch(authActions.setToken(data.token));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}


export const getUser = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.getUser();

      if (data.company.type !== 'company') {
        return Promise.reject('Not company admin provider.');
      } else {
        dispatch(authActions.setUser(data));
        return Promise.resolve(data);
      }
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}




export const authActions: any = authSlice.actions;


export default authSlice.reducer;
