import http from 'libs/http';


class TransactionApi {
  getTransactions = (url: string) => {
    return http.get(url);
  };
  // getTransactions = () => {
  //   return http.get('/v2/transactions/');
  // };
}


export default TransactionApi;