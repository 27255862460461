import React, {useEffect, useState} from 'react';
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {currencyFormatter} from 'libs/helpers';
import {useSelector} from 'react-redux';
import PieChart from 'components/PieChart';

interface PreviewEmployeeProps {
  employee: any;
}

const PreviewEmployee = ({ employee }: PreviewEmployeeProps) => {

  console.log(employee, 'emplopyee')
  const [pieChartData, setPieChartData] = useState<any>();
  const categories: any = useSelector((state: any) => state.benefitStore.categories);

  const getNameInitials = (firstName: string, lastName: string): any => {
    if (firstName && lastName) return firstName.slice(0, 1) + '.' + lastName.slice(0, 1) + '.';
    if (firstName && !lastName) return firstName.slice(0, 1);
    if (!firstName && lastName) return lastName.slice(0, 1);
    if (!firstName && !lastName) return '';
  };

  const getCategoryName = (categoryId: number) => {
    const target: any = categories.find((c:any) => c.id === categoryId);
    return parseCategoryName(target?.name);
  };

  const parseCategoryName = (name: string) => {
    if (name?.split('/')[1]) return name.split('/')[1];
    return name;
  };

  const getPieChartData = (): void => {
    if (employee) {
      setPieChartData({
        // labels: employee ? [...budgets.spentPerCategory[country.value].categories] : [],
        labels: ['Osnovna plata', 'Garantovani dodaci', 'Godišnji bonus', 'Stanje'],
        datasets: [
          {
            label: 'Test',
            backgroundColor: ["#1090CCFF", "#DBA32A", "#BFBAB8", "#59a058"],
            borderColor: '#FFFFFF',
            borderWidth: 2,
            hoverBackgroundColor: 'rgb(4,108,156)',
            hoverBorderColor: 'rgb(4,108,156)',
            data: [
              parseFloat(employee.baseSalary.replace(',', '')),
              parseFloat(employee.guaranteedAllowances.replace(',', '')),
              parseFloat(employee.annualBonus.replace(',', '')),
              parseFloat(employee.baseBalance.replace(',', '')) + employee.categoryBudgets.reduce((partialSum: any, a: any) => partialSum + parseFloat(a.amount), 0)
            ]
          },
        ],
      });
    }
  };

  const calculateBudgetPercent = (spent: number, budget: number): any => {
    if (budget <= 0) {
      console.error("Budžet mora biti veći od 0.");
      return 0;
    }

    const percentage: any = (spent / budget) * 100;
    return String(100 - percentage.toFixed(2));
  }

  useEffect(() => {
    getPieChartData();
  }, [])

  return(
    <div className="relative p-5 text-gray-700">
      <div className="absolute top-0 left-0 w-full h-20 bg-base-600 z-0"></div>

      <section className="relative w-full flex items-end gap-x-4">
        <div className="h-44 w-44 border-4 rounded-md border-white bg-gray-400 text-gray-600 flex items-center justify-center uppercase text-4xl font-bold">
          {getNameInitials(employee.firstName, employee.lastName)}
        </div>
        <div className="w-2/3 space-y-2 mb-2">
          <div className="text-xl font-bold flex items-center gap-x-4">
            <p>{employee.firstName} {employee.lastName}</p>
            <div className="flex items-center justify-center">
              {employee.isActive &&
                <span
                  className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full ${employee.isCompanyAdmin ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'} px-2 py-1 text-xs font-medium  ring-1 ring-inset`}>
                    {employee.isCompanyAdmin && employee.isActive ? 'Admin' : 'Korisnik'}
                  </span>
              }
              {!employee.isActive &&
                <span
                  className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-gray-400 text-white ring-gray-50/20 px-2 py-1 text-xs font-medium  ring-1 ring-inset`}>
                    Neaktivan
                  </span>
              }
            </div>
          </div>
          <p className="text-gray-500">{employee.position}</p>
          <div className="flex items-center justify-between">
            <div>
              <FontAwesomeIcon icon={faEnvelope} className="text-gray-400 mr-1"/>
              <a href={`mailto:${employee.email}`} className="text-base-600 tracking-tighter">{employee.email}</a>
            </div>
            <div>
              <FontAwesomeIcon icon={faPhone} className="text-gray-400 mr-1"/>
              <a href={`phone:${employee.phone}`} className="ml-auto tracking-tight">{employee.phone}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="flex gap-x-4 mt-4">
        <div className="w-1/2">
          <div className="bg-white shadow rounded-md p-3 py-3 flex items-center">
            <div className="flex items-center justify-between text-sm font-medium w-full">
              <p className="text-gray-700 font-bold"> Ukupan benefit budžet</p>
              <div className="flex items-center">
                <p className="text-base-600">{currencyFormatter(employee.baseBalance)} {employee.currency}</p>
                {/* staviti tooltip */}
                {/*<div className="tooltip">*/}
                {/*<InformationCircleIcon className="w-5 h-5 text-base-500" />*/}
                {/*<span className="tooltiptext shadow">{{ loc.total_benefit_budget_tooltip }}</span>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded-md p-3 space-y-3 mt-6">
            <div className="flex items-center justify-between text-sm font-medium w-full">
              <p className="text-gray-600 font-bold">Flex benefit budžet:</p>
              {/*<p className="text-base-600">{{ monetary(user.base_balance, user.currency)}}</p>*/}
              <p className="text-base-600">{currencyFormatter(employee.baseBalance)} {employee.currency}</p>
            </div>
            <div className="flex items-center justify-between text-sm font-medium w-full">
              <p className="text-gray-600"> Kupljeno benefita: </p>
              {/*<p className="text-yellow-500">{{user.chosen_benefits}}</p>*/}
              <p className="text-yellow-500">{employee.chosenBenefits}</p>
            </div>

            <div className="flex items-center justify-between text-sm font-medium w-full">
              <p className="text-gray-600"> Potrošeno: </p>
              {/*<p className="text-yellow-500">{{ monetary(user.spent, user.currency)}}</p>*/}
              <p className="text-yellow-500">{currencyFormatter(+employee.spent)} {employee.currency}</p>
            </div>

            <div className="flex items-center justify-between text-sm font-medium w-full">
              <p className="text-gray-600"> Trenutno stanje: </p>
              {/*<p className="text-base-600">{{ monetary(user.balance, user.currency)}}</p>*/}
              <p className="text-base-600">{currencyFormatter(employee.balance)} {employee.currency}</p>
            </div>

            <div className="w-100 border bg-white h-2 rounded-md">
              <div className="bg-base-600 h-2 rounded-md" style={{width: `${calculateBudgetPercent(employee.spent, employee.baseBalance)}%`}}></div>
            </div>
          </div>
          {/*<div v-if="$store.state.auth.user.category_budgets.length" className="mt-8">*/}
          <div className="mt-8">
            {employee.categoryBudgets && employee.categoryBudgets.map((budget: any) => (
              <div key={budget.category} className="space-y-3">
                <div className="bg-white shadow rounded-md p-3 mt-8 space-y-3">
                  <div className="flex items-center justify-between text-sm font-medium w-full">
                    <p className="text-gray-700 font-bold"> Kategorija: <span className="text-base-600">  {getCategoryName(budget.category)}</span></p>
                    <p className="text-base-600">{currencyFormatter(+budget.amount)} {employee.currency}</p>
                  </div>
                  <div className="flex items-center justify-between text-sm font-medium w-full">
                    <p className="text-gray-600"> Potrošeno: </p>
                    <p className="text-yellow-500 ">{currencyFormatter(+budget.amount - +budget.current)} {employee.currency}</p>
                  </div>
                  <div className="flex items-center justify-between text-sm font-medium w-full">
                    <p className="text-gray-600"> Trenutno stanje: </p>
                    <span className="text-base-600">{currencyFormatter(+budget.current)} {employee.currency}</span>
                  </div>
                  <div className="w-100 border bg-white h-2 rounded-md">
                    <div className="bg-base-500 h-2 rounded-md" style={{width: `${calculateBudgetPercent(+budget.amount - +budget.current, +budget.amount)}%`}}></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-1/2">
          <div className="z-30 h-52 flex items-center justify-center">
            {pieChartData && <PieChart data={pieChartData} size={220} key="pie12" id="preview"/>}
          </div>
          <ul className="space-y-2.5 text-sm font-medium text-gray-600 tracking-tight pl-1.5 mt-10">
            <li className="flex items-center gap-x-2">
              <div className="w-6 h-5" style={{backgroundColor: '#D37800'}}></div>
              {/*<p>{{loc.base_salary}} ({{monetary(user.base_salary, user.currency) + '/' + loc.yearly}})</p>*/}
              <p>Osnovna plata ({currencyFormatter(employee.baseSalary)} {employee.currency} / godišnje)</p>
            </li>
            <li className="flex items-center gap-x-2">
              <div className="w-6 h-5" style={{backgroundColor: '#33A61A'}}></div>
              {/*<p>{{loc.guaranteed_allowances}}({{monetary(user.guaranteed_allowances, user.currency) + '/' + loc.yearly}})</p>*/}
              <p>Garantovani dodaci ({currencyFormatter(employee.guaranteedAllowances)} {employee.currency} /
                godišnje) </p>
            </li>
            <li className="flex items-center gap-x-2">
              <div className="w-6 h-5" style={{backgroundColor: '#00D8FF'}}></div>
              {/*<p>{{loc.annual_bonus}} ({{monetary(user.annual_bonus, user.currency) + '/' + loc.yearly}})</p>*/}
              <p>Godišnji bonus ({currencyFormatter(employee.annualBonus)} {employee.currency} / godišnje)</p>
            </li>
            <li className="flex items-center gap-x-2">
              <div className="w-6 h-5" style={{backgroundColor: '#1468A5'}}></div>
              <p>Benefiti ({currencyFormatter(employee.baseBalance)} {employee.currency} / godišnje)</p>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default PreviewEmployee;