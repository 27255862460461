import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 44.7866, // Latituda za centar mape (npr. Beograd)
  lng: 20.4489, // Longituda za centar mape
};

const GoogleMaps = () => {

  const mapHtml = `
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe 
          src="https://maps.google.com/maps?q=Majdanska%20%C4%8Cukarica%2016,%20Beograd,%20Srbija&t=&z=13&ie=UTF8&iwloc=&output=embed" 
          width="600" 
          height="700" 
          style="border:0;" 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
        <a href="https://fmovies-online.net"></a>
        <br>
        <style>.mapouter{text-align:right; width: 100%; height: 100%;}</style>
        <a href="https://www.embedgooglemap.net"></a>
        <style>.gmap_canvas {overflow:hidden;background:none!important; height: 100%; width: 100%;}</style>
      </div>
    </div>
  `;

  return (
    <div
      className="w-full bg-white h-[700px] p-1 rounded-md shadow-xl"
      style={{aspectRatio: '1/1'}}
      dangerouslySetInnerHTML={{__html: mapHtml}}
    />
    // <LoadScript googleMapsApiKey="YOUR_API_KEY">
    //   <GoogleMap
    //     mapContainerStyle={containerStyle}
    //     center={center}
    //     zoom={10} // Podešavanje nivoa zumiranja
    //   >
    //     {/* Možete dodati markere, info prozore i druge komponente ovde */}
    //   </GoogleMap>
    // </LoadScript>
  );
};

export default React.memo(GoogleMaps);