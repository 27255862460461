import { configureStore } from '@reduxjs/toolkit';
import loaderStore from 'store/loader/loader.store';
import modalStore from 'store/modal/modal.store';
import sidebarStore from 'store/sidebar/sidebar.store';
import authStore from 'modules/auth/services/auth.store';
import benefitStore from 'modules/benefit/services/benefit.store';
import employeeStore from 'modules/employee/services/employee.store';
import analyticsStore from 'modules/analytics/services/analytics.store';
import suggestionsStore from 'modules/suggestions/services/suggestions.store';
import transactionStore from 'modules/transaction/services/transaction.store';
import reimbursementStore from 'modules/reimbursement/services/reimbursement.store';




const store: any = configureStore({
  reducer: {
    authStore,
    modalStore,
    loaderStore,
    sidebarStore,
    benefitStore,
    employeeStore,
    analyticsStore,
    suggestionsStore,
    transactionStore,
    reimbursementStore,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export default store;
