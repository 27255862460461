import { createSlice } from '@reduxjs/toolkit';

import BenefitApi from './benefit.api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { loaderActions } from 'store/loader/loader.store';
import {setHeaders} from "libs/http";

const api: BenefitApi = new BenefitApi();

interface benefitState {
  categories: any[];
  providers: any[];
  company: any;
  fixedBenefits: any[];
  nextPage: number | null;
  searchString: string;
  ordering: string;
  count: string | number;
}

const initialState: benefitState = {
  categories: [],
  providers: [],
  company: null,
  fixedBenefits: [],
  nextPage: null,
  searchString: '',
  ordering: '+',
  count: -1,
};

const benefitSlice: any = createSlice({
  name: 'benefit',
  initialState,
  reducers: {
    setCategories: (state: any, action: PayloadAction<any, any>): any => {
      state.categories = action.payload;
    },
    setProviders: (state: any, action: PayloadAction<any, any>): any => {
      const rawProviders: any[] = [...state.providers, ...action.payload];
      const uniqueProvidersMap: Map<any, any> = new Map();
      rawProviders.forEach(provider => {
        uniqueProvidersMap.set(provider.id, provider);
      });
      state.providers = Array.from(uniqueProvidersMap.values());
    },
    setFixedBenefits: (state: any, action: PayloadAction<any, any>): any => {
      const filteredFixed = action.payload.filter((b: any) => b.fixed);
      const rawBenefits: any[] = [...state.fixedBenefits, ...filteredFixed];

      const uniqueBenefitsMap: Map<any, any> = new Map();

      rawBenefits.forEach(benefit => {
        if (benefit && benefit.id) {
          uniqueBenefitsMap.set(benefit.id, benefit);
        }
      });

      state.fixedBenefits = Array.from(uniqueBenefitsMap.values());
    },
    setCompany: (state: any, action: PayloadAction<any, any>): any => {
      state.company = action.payload;
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.searchString = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.ordering = action.payload;
    },
    resetFixedBenefits: (state: any): any => {
      state.fixedBenefits = [];
    },
    resetCategories: (state: any): any => {
      state.categories = [];
    },
    resetProviders: (state: any): any => {
      state.providers = [];
    },
  },
});


export const getCategories = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.getCategories();
      dispatch(benefitActions.setCategories(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const configureCompany = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const companyId = getState().authStore.user.company.id;
      const { data } = await api.configureCompany(companyId, payload);
      dispatch(benefitActions.setCompany(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const getProviders = (resetData: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = getState().benefitStore.nextPage;
      const companyId = getState().authStore.user.company.id;
      const searchString = getState().benefitStore.searchString;
      let url: string = `/v2/admin/providers/?providers=${companyId}&search=${searchString}`;

      if (resetData && searchString) dispatch(benefitActions.resetProviders())

      if (!resetData && nextPage) {
        url = nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getProviders(url);
      dispatch(benefitActions.setNextPage(data.next));
      dispatch(benefitActions.setCount(data.count));
      dispatch(benefitActions.setProviders(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
}

export const getCompany = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const companyId = getState().authStore.user.company.id;
      const { data } = await api.getCompany(companyId);
      dispatch(benefitActions.setCompany(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const getFixedBenefits = (reset: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = getState().benefitStore.nextPage;
      const searchString = getState().benefitStore.searchString;

      let url = `/v2/admin/benefits/?search=${searchString}`;

      // if (searchString) dispatch(benefitActions.resetFixedBenefits());

      if (!reset && nextPage) {
        url = nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getFixedBenefits(url);
      dispatch(benefitActions.setNextPage(data.next));
      dispatch(benefitActions.setCount(data.count));
      dispatch(benefitActions.setFixedBenefits(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
}

export const addFixedBenefit = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'Content-type': 'multipart/form-data'});
      const { data } = await api.addFixedBenefit(payload);
      await setHeaders({'Content-type': 'application/json'});
      dispatch(benefitActions.resetFixedBenefits());
      dispatch(benefitActions.setNextPage(0));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const editFixedBenefit = (benefitId: number, payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'Content-type': 'multipart/form-data'});
      const { data } = await api.editFixedBenefit(benefitId, payload);
      await setHeaders({'Content-type': 'application/json'});
      dispatch(benefitActions.resetFixedBenefits());
      dispatch(benefitActions.setNextPage(0));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject({e: 'Nesto je'});
    }
  }
}
export const editPromo = (benefitId: number, payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.editFixedBenefit(benefitId, payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject({e: 'Nesto je'});
    }
  }
}

export const deleteImage = (imageId: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.deleteImage(imageId);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const uploadImages = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({ 'content-type': 'multipart/form-data' });
      const { data } = await api.uploadImages(payload);
      await setHeaders({ 'content-type': 'application/json' });
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const deleteFixedBenefit = (benefitId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.deleteFixedBenefit(benefitId);
      dispatch(benefitActions.resetFixedBenefits());
      dispatch(benefitActions.setNextPage(0));
      dispatch(getFixedBenefits(true));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}


export const benefitActions: any = benefitSlice.actions;

export default benefitSlice.reducer;
