import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { loaderActions } from 'store/loader/loader.store';
import SuggestionsApi from 'modules/suggestions/services/suggestions.api';

const api: SuggestionsApi = new SuggestionsApi();


interface SuggestionsState {
  suggestions: any[];
  nextPage: number | null;
  count: string | number;
}

const initialState: SuggestionsState = {
  suggestions: [],
  nextPage: null,
  count: -1,
};


const suggestionsSlice: any = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    setSuggestions: (state: any, action: PayloadAction<any, any>): any => {
      const rawSuggestions: any[] = [...state.suggestions, ...action.payload.results];

      const uniqueSuggestionsMap: Map<any, any> = new Map();

      rawSuggestions.forEach(suggestion => {
        if (suggestion && suggestion.id) {
          if (suggestion.author.company === action.payload.companyId) {
            uniqueSuggestionsMap.set(suggestion.id, suggestion);
          }
        }
      });

      state.suggestions = Array.from(uniqueSuggestionsMap.values());
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    resetSuggestions: (state: any): any => {
      state.suggestions = [];
    },
  },
});


export const getSuggestions = (resetData: boolean = false) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = getState().suggestionsStore.nextPage;
      const user = getState().authStore.user;
      const companyId = user.company.id;

      let url: string = `/v2/admin/suggestions/`;

      if (nextPage) {
        url = nextPage;
      }


      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getSuggestions(url);
      dispatch(suggestionsActions.setNextPage(data.next));
      dispatch(suggestionsActions.setCount(data.count));
      dispatch(suggestionsActions.setSuggestions({ results: data.results, companyId: companyId }));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}


export const suggestionsActions: any = suggestionsSlice.actions;

export default suggestionsSlice.reducer;
