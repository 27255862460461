import React, {useEffect, useState, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import { faCircleXmark, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DatepickerProps {
  placeholder?: string;
  value?: any;
  format?: string;
  classNames?: string;
  onChange: (date: any) => void;
}

const CustomInput = forwardRef<HTMLInputElement, { value: string; onClick: () => void; onClear: () => void; placeholderText?: string }>(({ value, onClick, onClear, placeholderText }, ref) => (
  <div className="relative">
    <div className="absolute left-3 top-0 z-30 h-full flex items-center">
      <FontAwesomeIcon icon={faCalendar} className="text-gray-300" />
    </div>
    <input
      type="text"
      value={value}
      readOnly
      ref={ref}
      onClick={onClick}
      placeholder={placeholderText}
      className="w-full pl-9 h-10 z-20 relative rounded-lg border hover:border-base-500 focus:ring-2 focus:ring-base-500 focus:outline-0 animation duration-200 ease-in-out"
    />
    {value && (
      <button
        onClick={() => onClear()}
        className="absolute right-2 top-2 text-red-600 z-20"
      >
        <FontAwesomeIcon icon={faCircleXmark} />
      </button>
    )}
  </div>
));


const Datepicker = ({ value, classNames, format, placeholder, onChange }: DatepickerProps) => {
  const [date, setDate] = useState<any>('');

  const onChangeHandler = (date: string) => {
    setDate(date);
    onChange(date);
  }

  useEffect(() => {
    if (value) setDate(value);
  }, []);

  return (
    <DatePicker
      selected={date}
      dateFormat={format}
      icon={true}
      onSelect={(value: any) => onChangeHandler(value)}
      onChange={(value: any) => onChangeHandler(value)}
      customInput={
        <CustomInput
          onClick={() => null}
          value={date ? date : ''}
          onClear={() => onChangeHandler('')}
          placeholderText={placeholder}
        />
      }
    />
  );
};

export default Datepicker;