import React, {useEffect, useState, useRef} from 'react';
import Filters from 'modules/transaction/UI/Filters';
import  { formatDate } from 'libs/datetime';
import { monetaryFormatter } from 'libs/monetary';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faHourglassHalf,
  faTimes,
  faCheck,
  faFile,
  faListCheck, faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import {useDispatch, useSelector} from 'react-redux';
import {transactionActions, getTransactions} from 'modules/transaction/services/transaction.store';
import { ElementLoader } from 'components/Loaders';
import InfiniteScroll from "react-infinite-scroll-component";
import http from "libs/http";
import {toast} from "react-toastify";

const thClassNames = 'h-12 pl-2 flex items-center';
const tdClassNames = 'h-20 pl-2 flex items-center';

const Transactions = () => {


  const dispatch: any = useDispatch();
  const [expanded, setExpanded] = useState<number | null>(null);
  const count: any = useSelector((state: any) => state.transactionStore.count);
  const nextPage: any = useSelector((state: any) => state.transactionStore.nextPage);
  const transactions = useSelector((state: any) => state.transactionStore.transactions);
  const searchString: any = useSelector((state: any) => state.transactionStore.searchString);
  const status: any = useSelector((state: any) => state.transactionStore.status);
  const dateFrom: any = useSelector((state: any) => state.transactionStore.dateFrom);
  const dateTo: any = useSelector((state: any) => state.transactionStore.dateTo);
  const user = useSelector((state: any) => state.authStore.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableHeight, setTableHeight] = useState<number>(0);

  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === transactions.length;
  }, [count, transactions]);

  const toggleExpanded = (id: number) => {
    if (id === expanded) setExpanded(null);
    else setExpanded(id);
  }

  useEffect(() => {
    return () => {
      dispatch(transactionActions.setNextPage(null));
      dispatch(transactionActions.setCount(0));
      dispatch(transactionActions.resetTransactions());
    }
  }, []);

  const fetchMoreTransactions = async (resetData: boolean = false): Promise<void> => {
    try {
      await dispatch(getTransactions(resetData));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const resetTransactions = async () => {
    await dispatch(transactionActions.setCount(0));
    await dispatch(transactionActions.resetTransactions());
    await dispatch(transactionActions.setNextPage(null));
  }

  useEffect(() => {
    resetTransactions();
    fetchMoreTransactions(true);
  }, [searchString, status, dateFrom, dateTo]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreTransactions();
      }
    };

    // @ts-ignore
    window.addEventListener('scroll', handleScroll);
    return () => {
      // @ts-ignore
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const searchStringHandler = (payload: string) => {
    dispatch(transactionActions.setSearchString(payload));
  };

  const dateFromHandler = (payload: string) => {
    const date: string = formatDate(payload, 'YYYY-MM-DD');
    dispatch(transactionActions.setDateFrom(date));
  };

  const dateToHandler = (payload: string) => {
    const date: string = formatDate(payload, 'YYYY-MM-DD');
    dispatch(transactionActions.setDateTo(date));
  };

  const statusHandler = (payload: string) => {
    dispatch(transactionActions.setStatus(payload));
  };

  const statusUrlHandler = (status: string) => {
    switch (status) {
      case 'finished':
        return `&finished=true&canceled=false`;
      case 'in_progress':
        return '&finished=false&canceled=false';
      case 'cancelled':
        return '&canceled=true';
      default:
        return '';
    }
  };

  const exportHandler = async () => {
    try {
      let url = `/v2/admin/transactions/export?search=${searchString}`
      if (dateFrom) url += `&created_from=${dateFrom}`;
      if (dateTo) url += `&created_to=${dateTo}`;
      if (status) url += statusUrlHandler(status);
      await http.get(url);
      toast.success('Uspešno ste započeli preuzimanje!');
    } catch (e: any) {
      console.log(e, 'ee')
      toast.error('Nešto nije u redu!');
    }
  };

  const getTableHeight = () => {
    let height: number =  document.documentElement.clientHeight - 255;
    setTableHeight(height);
  }

  useEffect(() => {
    getTableHeight();
  }, [transactions]);

  console.log(transactions, 'trans')

  return (
    <>
      <Filters
        onExport={() => exportHandler()}
        onSearch={(str: string) => searchStringHandler(str)}
        onStatusChange={(status: any) => statusHandler(status.value)}
        onEndDateChange={(date: any) => dateToHandler(date)}
        onStartDateChange={(date: any) => dateFromHandler(date)}
      />

      <section id="table" className={`${loading && 'min-h-[70vh]'} relative w-full max-h-[calc(100vh-220px)] mt-7 overflow-y-auto rounded-b-lg overflow-hidden border`}>
        {tableHeight > 0 && transactions.length > 0 && <InfiniteScroll
            dataLength={transactions.length}
            next={fetchMoreTransactions}
            height={tableHeight}
            hasMore={count !== transactions.length}
            loader={""}
            scrollThreshold={1}
        >
        <div className="sticky top-0 z-10 w-full h-12 rounded-t-lg bg-base-600 text-white text-sm font-bold flex divide-x-2 divide-white items-center">
          <div className={`w-1/12 ${thClassNames}`}>ID</div>
          <div className={`w-2/12 ${thClassNames}`}>Datum</div>
          <div className={`w-2/12 ${thClassNames}`}>Zaposleni</div>
          <div className={`w-3/12 ${thClassNames}`}>Benefit provajder</div>
          <div className={`w-1/12 ${thClassNames} justify-center !pl-0`}>Prilog</div>
          <div className={`w-2/12 ${thClassNames}`}>Ukupna cena</div>
          <div className={`w-1/12 ${thClassNames} justify-center !pl-0`}>Status</div>
        </div>
        {transactions && transactions.length > 0 && transactions.map((t: any) =>
          <div key={t.id} className={`${t.id === expanded && 'border border-base-600 border-box transition duration-500 ease-in-out'} hover:bg-gray-50`}>
            <div onClick={() => toggleExpanded(t.id)}
                 className="w-full h-20 bg-primary-50 border-t-2 border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center cursor-pointer">
              <div className={`w-1/12 font-medium ${tdClassNames} flex items-center justify-between`}>{t.id}</div>
              <div className={`w-2/12 ${tdClassNames}`}>{formatDate(t.createdAt, 'DD.MM.YYYY.')}</div>
              <div className={`w-2/12 ${tdClassNames}`}>{ t.user.firstName } { t.user.lastName }</div>
              <div className={`w-3/12 h-20 pl-2 flex items-center`}>
                <div>
                  {t.providers && t.providers.length > 0 && t.providers.map((provider: any) =>
                    <p key={provider}>{ provider }</p>
                  )}
                </div>
              </div>
              <div className={`w-1/12 ${tdClassNames} justify-center !pl-0`}>
                <Tooltip id="download" className="z-10" />
                <a onClick={(e: any) => e.stopPropagation()} href={t.invoice[0]?.pdf} target="_blank">
                  <FontAwesomeIcon
                    data-tooltip-id="download"
                    data-tooltip-content="Klikni da preuzmeš  "
                    icon={faFile}
                    className="hover:text-base-500 text-2xl animation duration-200 ease-in-out"
                  />
                </a>
              </div>
              <div className={`w-2/12 ${tdClassNames} font-medium`}>{monetaryFormatter(t.price)}</div>
              <div className={`w-1/12 flex items-center justify-center ${tdClassNames} !pl-0`}>
                <Tooltip id="status" className="z-10" />
                {!t.finished &&
                  <span
                      data-tooltip-id="status"
                      data-tooltip-content="U procesu"
                      className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-yellow-500`}>
                    <FontAwesomeIcon icon={faHourglassHalf}/>
                  </span>
                }
                {t.finished && t.canceled &&
                  <span
                      data-tooltip-id="status"
                      data-tooltip-content="Stornirano"
                      className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-red-600`}>
                    <FontAwesomeIcon icon={faTimes}/>
                  </span>
                }

                {t.finished && !t.canceled &&
                    <span
                        data-tooltip-id="status"
                        data-tooltip-content="Završeno"
                        className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-green-600`}>
                    <FontAwesomeIcon icon={faCheck}/>
                  </span>
                }
              </div>
            </div>
            <div>
              {t.benefitsCountsString &&
                <div
                  className={`collapsible-content ${t.id === expanded && 'open'} w-full bg-primary-50 ${t.id === expanded && 'border-t-2'} border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center`}>
                  <div className={`w-1/12 font-medium ${tdClassNames} flex items-center justify-between`}></div>
                  <div className={`w-2/12 ${tdClassNames}`}>
                    <div className="space-y-1">
                      <p><span className="font-medium">Kompanijski ID:</span> {t.idCompany}</p>
                      <p className="text-base-600"><span className="font-medium">HoB User ID: {t.user.id}</span></p>
                    </div>
                  </div>
                  <div className={`w-2/12 pr-2 ${tdClassNames}`}>
                    <div className="space-y-1 truncate">
                      <p className="font-medium">Mejl</p>
                      <Tooltip id="email" className="z-10" />
                      <p
                        data-tooltip-id="email"
                        data-tooltip-content={t.email}
                        className="text-base-600 font-medium truncate">
                        { t.email ? t.email : '/' }
                      </p>
                    </div>
                  </div>
                  <div className={`w-6/12 ${tdClassNames}`}>
                    <div className="space-y-1">
                      <p className="font-medium">Naziv benefita</p>
                      <Tooltip id="name" className="z-10" />
                      <p
                        data-tooltip-id="name"
                        data-tooltip-content={t.benefitsCountsString}
                        className="text-left text-base-600 font-medium">
                        {t.benefitsCountsString}
                      </p>
                    </div>
                  </div>
                  {/*<div className={`w-1/12 ${tdClassNames} flex items-center justify-center !pl-0`}>*/}
                  {/*  <div className="space-y-1">*/}
                  {/*    <p className="font-medium">Količina</p>*/}
                  {/*    /!*<p className="text-center text-base-600 font-medium">{b.count}</p>*!/*/}
                  {/*    <p className="text-center text-base-600 font-medium"></p>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className={`w-2/12 ${tdClassNames}`}>*/}
                  {/*  <div className="space-y-1">*/}
                  {/*    <p className="font-medium">Cena</p>*/}
                  {/*    <p className="text-center text-base-600 font-medium">{monetaryFormatter(b.price)}</p>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className={`w-1/12 flex items-center justify-center ${tdClassNames}`}></div>
                  {/*<div className={`w-2/12 ${tdClassNames}`}>Ukupna cena</div>*/}
                  {/*<div className={`w-1/12 ${tdClassNames}`}>Status</div>*/}
                </div>
              }
            </div>
          </div>
        )}
        </InfiniteScroll>}
        {loading && <ElementLoader size="regular" color="primary"
                                   classNames="flex items-center justify-center backdrop-blur-[2px] rounded-lg"/>}
        {!loading && transactions.length === 0 &&
          <div className="h-[70vh] flex items-center justify-center text-gray-600 text-sm font-medium">
            <div className="text-center space-y-4">
              <FontAwesomeIcon icon={faListCheck} className="text-4xl"/>
              <p className="font-medium">Nema pronađenih kupovina!</p>
            </div>
          </div>
        }
      </section>

    </>
  );
};

export default Transactions;