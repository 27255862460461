import http from 'libs/http';

class EmployeeApi {
  getEmployees = (url: string) => {
    return http.get(url);
  };

  addEmployee = (payload: any) => {
    return http.post('/v2/admin/employees/', payload);
  };

  editEmployee = (id: number, payload: any) => {
    return http.patch(`/v2/admin/employees/${id}/`, payload);
  };

  // deactivateEmployee = (employeeId: any) => {
  //   return http.patch(`/v2/admin/employees/${employeeId}/`, { hide: true });
  // };
  //
  // deleteEmployee = (employeeId: any) => {
  //   return http.patch(`/v2/admin/employees/${employeeId}/`, { isActive: false });
  // };

  deactivateEmployee = (employeeId: any) => {
    return http.patch(`/v2/admin/employees/${employeeId}/`, { hide: false, is_active: false });
  };

  deleteEmployee = (employeeId: any) => {
    return http.patch(`/v2/admin/employees/${employeeId}/`, { hide: true, isActive: false });
  };

  addCategoryBudget = (payload: any) => {
    return http.delete('/v2/admin/category-budget/', payload);
  };

  editCategoryBudget = (id: number, payload: any) => {
    return http.patch(`/v2/admin/category-budget/${id}/`, payload);
  };

  deleteCategoryBudget = (id: number) => {
    return http.delete(`/v2/admin/category-budget/${id}/`);
  };

  importUsers = (payload: any) => {
    return http.post('/v2/admin/table-file-create-users/', payload);
  };

  exportUsers = (payload: any) => {
    return http.post('/v2/admin/employees/export', payload);
  };

}


export default EmployeeApi;