import http from 'libs/http';


class SuggestionsApi {
  getSuggestions = (url: string) => {
    return http.get(url);
  };
}


export default SuggestionsApi;