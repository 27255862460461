import React, { ReactNode, SyntheticEvent } from 'react';
import { Tooltip } from 'react-tooltip';


type ButtonBackground =
  | 'default'
  | 'success'
  | 'primary'
  | 'outline-primary'
  | 'dark'
  | 'transparent'
  | 'warning';
type ButtonSize = 'small' | 'regular' | 'medium' | 'large';
type ClickHandler = (e: SyntheticEvent) => void;


interface ButtonProps {
  children: ReactNode;
  className?: string;
  size: ButtonSize;
  bg: ButtonBackground;
  tooltip?: string;
  onClick: ClickHandler;
  disabled?: boolean;
  rounded?: boolean;
  style?: any;
}


const Button: any = ({
                       children,
                       className,
                       size,
                       bg,
                       tooltip,
                       onClick,
                       disabled,
                       rounded = true,
                       style,
                     }: ButtonProps) => {


  const calculateBgColor = (type: string): string | undefined => {
    switch (type) {
      case 'default':
        return `bg-base-600 text-white ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-base-700'}`;
      case 'primary':
        return `bg-base-600 text-white ${disabled ? 'bg-opacity-75 cursor-not-allowed' : 'hover:bg-base-500'}`;
      case 'info':
        return `bg-yellow-600 text-white ${disabled ? 'bg-opacity-75 cursor-not-allowed' : 'hover:bg-yellow-500'}`;
      case 'outline-primary':
        return `border-2 border-base-600 text-base-600 ${disabled ? 'bg-opacity-75 cursor-not-allowed' : 'hover:bg-base-500 hover:border-base-500 hover:text-white'}`;
      case 'outline-secondary':
        return `border border text-primary-900 ${disabled ? 'bg-opacity-75 cursor-not-allowed' : 'hover:bg-primary-900 hover:text-white'}`;
      case 'white':
        return `bg-white border-2 border-base-600 text-base-600 ${disabled ? 'bg-opacity-75 cursor-not-allowed' : 'hover:bg-base-500 hover:border-base-500 hover:text-white'}`;
      case 'success':
        return `text-white ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-500'}`;
      case 'dark':
        return `bg-black text-white ${disabled ? 'bg-opacity-75 cursor-not-allowed' : 'hover:bg-opacity-75'}`;
      case 'warning':
        return `text-white ${disabled ? 'bg-gray-400  cursor-not-allowed' : 'bg-red-600 hover:bg-red-500'}`;
      case 'outline-warning':
        return `border-2 border-red-600 hover:bg-red-600 text-red-600 hover:text-white   ${disabled ? 'bg-opacity-75 cursor-not-allowed' : 'hover:bg-red-700'}`;
    }
  }


  const calculateSize = (size: string): string | undefined => {
    switch (size) {
      case 'small':
        return 'h-8 text-sm';
      case 'regular':
        return 'h-10'
      case 'medium':
        return 'h-12';
      case 'large':
        return 'h-14';
      default:
        return 'h-full';
    }
  }


  return (
    <>
      {tooltip && <Tooltip id={tooltip}/>}
      <button
        data-tooltip-id={tooltip}
        data-tooltip-content={tooltip}
        disabled={disabled}
        className={`${className} ${calculateBgColor(bg)} ${calculateSize(size)} ${rounded ? 'rounded-lg' : 'rounded'} font-medium transition ease-in-out duration-200 select-none`}
        onClick={onClick}
        style={style}
      >
        {children}
      </button>
    </>
  )
};


export default Button;
