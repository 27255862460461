import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import EmployeeApi from './employee.api';
import { loaderActions } from 'store/loader/loader.store';
import {useState} from "react";


const api: EmployeeApi = new EmployeeApi();

interface EmployeeState {
  employees: any[];
  nextPage: number | null;
  searchString: string;
  filterBy: string;
  sortBy: string;
  ordering: string;
  count: string | number;
}

const initialState: EmployeeState = {
  employees: [],
  nextPage: null,
  searchString: '',
  filterBy: '',
  sortBy: 'id',
  ordering: '-',
  count: -1,
};

const employeeSlice: any = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployees: (state: any, action: PayloadAction<any, any>): any => {
      const rawEmployees = [...state.employees, ...action.payload];
      const uniqueEmployeesMap = new Map();
      rawEmployees.forEach(employee => {
        uniqueEmployeesMap.set(employee.id, employee);
      });
      state.employees = Array.from(uniqueEmployeesMap.values());
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.searchString = action.payload;
    },
    setFilterBy: (state: any, action: PayloadAction<string, any>): any => {
      state.filterBy = action.payload;
    },
    setSortBy: (state: any, action: PayloadAction<string, any>): any => {
      state.sortBy = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.ordering = action.payload;
    },
    resetEmployees: (state: any): any => {
      state.employees = [];
    },
  },
});


export const getEmployees = (resetData: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = await getState().employeeStore.nextPage;
      const searchString = await getState().employeeStore.searchString;
      const filterBy = await getState().employeeStore.filterBy;
      const sortBy = await getState().employeeStore.sortBy;
      const ordering = await getState().employeeStore.ordering;

      let url: string = `/v2/admin/employees/?ordering=${ordering}&search=${searchString}`;
      console.log(sortBy, 'SORT BY')
      if (sortBy) url += `&sort_by=${sortBy}`;
      if (filterBy) url += `&filter_by=${filterBy}`;

      if (resetData) dispatch(employeeActions.resetEmployees())

      if (!resetData && nextPage) {
        url = nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getEmployees(url);
      dispatch(employeeActions.setNextPage(data.next));
      dispatch(employeeActions.setCount(data.count));
      dispatch(employeeActions.setEmployees(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const addEmployee = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(false));
      const { data } = await api.addEmployee(payload);
      dispatch(employeeActions.resetEmployees());
      dispatch(employeeActions.setNextPage(0));
      dispatch(getEmployees(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const editEmployee = (id: number, payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.editEmployee(id, payload);
      dispatch(employeeActions.resetEmployees());
      dispatch(employeeActions.setNextPage(0));
      dispatch(getEmployees(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};


export const deactivateEmployee = (id: number) => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.deactivateEmployee(id);
      dispatch(employeeActions.resetEmployees());
      dispatch(employeeActions.setNextPage(0));
      await dispatch(getEmployees(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const deleteEmployee = (id: number) => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.deleteEmployee(id);
      dispatch(employeeActions.resetEmployees());
      dispatch(employeeActions.setNextPage(0));
      await dispatch(getEmployees(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const addCategoryBudget = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.addCategoryBudget(payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const editCategoryBudget = (id: number, payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.editCategoryBudget(id, payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const deleteCategoryBudget = (id: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.deleteCategoryBudget(id);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const importUsers = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.importUsers(payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const exportUsers = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.exportUsers(payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};



export const employeeActions: any = employeeSlice.actions;

export default employeeSlice.reducer;
